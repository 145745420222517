/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Button, Snackbar } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { BoxPaper, DashPaper, Alert } from "../components/styledComponents";
import { Footer, ResponsiveAppBar } from "../components/common";
import { QrReader } from "react-qr-reader-extended";
import { useNavigate } from "react-router-dom";
import { hcpDashboardText } from "../data/content";
import { getMeetingIdVerification } from "../services/hcpmappingservice";
import { SessionContext } from "../providers/provider";
import BackdropLoader from "../components/loader";

function HcpDashboard() {
  let navigate = useNavigate();
  const userSession = useContext(SessionContext);
  const [scanerror, setScanError] = useState(false);
  const [startScan, setStartScan] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const handleScan = () => {
    if (startScan === false) {
      setScanError(false);
    }
    setStartScan(!startScan);
  };

  const handleAlertClick = (message, type) => {
    setAlert({
      message,
      type,
    });
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  useEffect(() => {
    async function verifyMeetingId() {
      setIsLoading(true);
      const accessToken = localStorage.getItem("apitoken");
      let verifyResult = await getMeetingIdVerification(accessToken, {
        meeting_id: window.location.pathname.split("/")[5],
      });
      setIsLoading(false);
      if (
        localStorage.getItem("redirectto") &&
        localStorage.getItem("redirectto").includes("submit")
      ) {
        if (verifyResult.statusCode === "200") {
          setIsLoading(true);
        } else if (verifyResult.statusCode === 403) {
          handleAlertClick(
            `Your meeting has been expired, Please try to join with the valid meeting QR code.`,
            "error"
          );
        } else {
          navigate("/404NotFound");
        }
      }
    }
    verifyMeetingId();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ResponsiveAppBar />
      <Snackbar
        open={alertOpen}
        sx={{ height: "100%" }}
        onClose={handleAlertClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alert.type}
          sx={{ fontSize: "16px", pt: 1, pb: 1, whiteSpace: "pre-line" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      {isLoading ? (
        <BackdropLoader show={isLoading} />
      ) : (
        <>
          <BoxPaper>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <DashPaper sx={{ minHeight: "300px", pt: 4, pl: 2 }}>
                  <Typography fontWeight="700">
                    Welcome to Scan QR code Page, {userSession.userFirstName}{" "}
                    {userSession.userLastName}.
                    <br />
                    <br />
                  </Typography>
                  <Typography>{hcpDashboardText.guidelines}</Typography>

                  <Box pt={5} pb={2} textAlign="center">
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleScan}
                      sx={{ fontWeight: "bold" }}
                    >
                      {startScan ? "Stop Scan" : "Start Scan"}
                    </Button>
                  </Box>

                  <Grid
                    container
                    sx={{ textAlign: "center", justifyContent: "center" }}
                  >
                    {startScan && (
                      <Box
                        sx={{
                          alignItems: "center",
                          width: 350,
                          height: 350,
                          textAlign: "center",
                          justifyContent: "center",
                          margin: 2,
                        }}
                      >
                        <QrReader
                          constraints={{
                            facingMode: "environment",
                          }}
                          onResult={(result, error) => {
                            if (!!result) {
                              setStartScan(false);
                              async function verifyMeetingId() {
                                setIsLoading(true);
                                const meeting_id = result.text.split("/")[5];
                                const accessToken =
                                  localStorage.getItem("apitoken");
                                let verifyResult =
                                  await getMeetingIdVerification(accessToken, {
                                    meeting_id: meeting_id,
                                  });
                                setIsLoading(false);
                                if (
                                  verifyResult.statusCode === "200" &&
                                  verifyResult
                                ) {
                                  handleAlertClick(
                                    "QR Scanned successfully.",
                                    "success"
                                  );
                                  setTimeout(() => {
                                    window.location.assign(result.text);
                                  }, 2000);
                                } else if (verifyResult.statusCode === 403) {
                                  // window.location.assign(result.text);
                                  handleAlertClick(
                                    `Your meeting has been expired, Please try to join with the valid meeting QR code.`,
                                    "error"
                                  );
                                } else {
                                  setScanError(true);
                                  handleAlertClick(
                                    "Invalid Meeting QR",
                                    "error"
                                  );
                                }
                              }
                              verifyMeetingId();
                            }
                            if (!!error) {
                              console.info(error);
                            }
                          }}
                        />
                      </Box>
                    )}
                  </Grid>
                </DashPaper>
              </Grid>
            </Grid>
          </BoxPaper>
          <Footer />
        </>
      )}
    </>
  );
}

export default HcpDashboard;
