/* eslint-disable react/jsx-no-undef */
import { useState, useRef, useEffect, useContext } from "react";
import { Box, Grid, Button, Stack, Snackbar, Typography } from "@mui/material";
import {
  Alert,
  BoxPaper,
  DashPaper,
  Input,
  Title,
} from "../components/styledComponents";
import { Footer, ResponsiveAppBar } from "../components/common";
import SignaturePad from "react-signature-canvas";
import { addAttendeeDetails } from "../services/attendeeservice";
import { encryptData } from "../providers/encryptProvider";
import { SessionContext } from "../providers/provider";
import { Asterisk } from "../components/styledComponents";
import {
  getHCPDetailsByEmail,
  getMeetingIdVerification,
} from "../services/hcpmappingservice";
import { useNavigate } from "react-router-dom";
import BackdropLoader from "../components/loader";

function HCPForm(props) {
  const userSession = useContext(SessionContext);
  const [imageURL, setImageURL] = useState(null);
  const [userDetails, setUserDetails] = useState();
  const [nationalId, setNationalId] = useState("");
  const [professionalId, setProfessionalId] = useState("");
  const [nationalIDHT, setNationalIDHT] = useState("");
  const [professionaIDHT, setProfessionalIDHT] = useState("");
  const [signatureError, setSignatureError] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    let accessToken = localStorage.getItem("apitoken");
    async function checkMeeting() {
      setIsLoading(true);
      let meetingCheck = await getMeetingIdVerification(accessToken, {
        meeting_id: window.location.pathname.split("/")[3],
      });
      setIsLoading(false);
      if (meetingCheck.message === "Successful") {
        setMeetingId(window.location.pathname.split("/")[3]);
      } else {
        navigate("/404NotFound");
      }
    }
    checkMeeting();
    async function userHcpDetails() {
      setIsLoading(true);
      let temp = await getHCPDetailsByEmail(accessToken, {
        email_id: userSession.userEmail,
      });
      temp = temp[0];
      temp = temp[0];
      setUserDetails(temp);
      setNationalId(temp && temp.national_id);
      setProfessionalId(temp && temp.professional_id);
      setIsLoading(false);
    }
    userHcpDetails();
  }, [navigate, userSession]);

  const [meetingId, setMeetingId] = useState("");
  const [emptyCanvas, setEmptyCanvas] = useState(true);

  const sigCanvas = useRef({});
  const Clear = () => {
    setImageURL(null);
    sigCanvas.current.clear();
    setSignatureError(false);
  };
  const Save = () => {
    if (sigCanvas.current.isEmpty()) {
      setEmptyCanvas(false);
      setSignatureError(true);
    } else {
      setEmptyCanvas(false);
      setSignatureError(false);
      setImageURL(sigCanvas.current.getCanvas().toDataURL("Image/png"));
    }
  };

  const handleAlertClick = (message, type) => {
    setAlert({
      message,
      type,
    });
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const resetError = () => {
    setNationalIDHT("");
    setProfessionalIDHT("");
    setSignatureError(false);
  };
  const valid = () => {
    let flag = false;
    if (nationalId === "" || nationalId === undefined) {
      setNationalIDHT("Please Provide National ID");
      flag = true;
    }
    if (nationalId && nationalId.length < 6) {
      setNationalIDHT("length must be greater than 5");
      flag = true;
    }
    if (professionalId === "" || professionalId === undefined) {
      setProfessionalIDHT("Please Provide Professional ID");
      flag = true;
    }
    if (professionalId && professionalId.length < 6) {
      setProfessionalIDHT("length must be greater than 5");
      flag = true;
    }
    if (emptyCanvas) {
      setSignatureError(true);
      flag = true;
    }
    return flag;
  };

  const handleSubmit = () => {
    resetError();
    if (valid()) {
      return;
    }
    else {
      let accessToken = localStorage.getItem("apitoken");
      async function submitData() {
        if (imageURL === null) {
          setEmptyCanvas(false);
          setSignatureError(true);
        } else {
          setIsLoading(true);

          let temp = await addAttendeeDetails(
            {
              customer_id: userSession.hcpCustId,
              hcp_firstname: `${userSession.userFirstName}`,
              hcp_lastname: `${userSession.userLastName}`,
              meeting_id: meetingId,
              national_id: encryptData(
                nationalId,
                userSession.reactAppEncryptionKey
              ),
              professional_id: encryptData(
                professionalId,
                userSession.reactAppEncryptionKey
              ),
              hcp_specialization: userSession.hcpSpecilaity,
              hcp_signature: encryptData(
                imageURL,
                userSession.reactAppEncryptionKey
              ),
              hcp_signature_filename: `${meetingId}_${userSession.userFirstName}${userSession.userLastName}`,
              email_id: userSession.userEmail,
              hcp_mobile: encryptData(
                userSession.userPhoneNumber,
                userSession.reactAppEncryptionKey
              ),
            },
            accessToken
          );
          setIsLoading(false);

          if (temp.statusCode === 500) {
            handleAlertClick(
              "You have already submitted the information",
              "error"
            );
          } else if (temp.message === "Successful") {
            handleAlertClick(
              `You have successfully registered to the meeting. Kindly take the screenshot and show it at the counter.
           Attendee id: ${temp.result.attendee_id}
           Meeting id: ${temp.result.meeting_id}.`,
              "success"
            );
            setDisableSubmit(true);
            // setTimeout(() => {
            //   navigate(`/hcp/dashboard`);
            // }, 60000);
          } else {
            handleAlertClick(temp.message, "error");
          }
        }
      }
      submitData();
    }
  };

  // const onClose = () => {
  //   setImageURL(null);
  //   setEmptyCanvas(true);
  // };

  const handleChange = (event) => {
    setNationalId(event.target.value);
    if (event.target.value.length !== 0 && event.target.value.length < 6) {
      setNationalIDHT("length must be greater than 5");
    } else {
      setNationalIDHT("");
    }
  };

  const handleprofessionalidChange = (event) => {
    setProfessionalId(event.target.value);
    if (event.target.value.length !== 0 && event.target.value.length < 6) {
      setProfessionalIDHT("length must be greater than 5");
    } else {
      setProfessionalIDHT("");
    }
  };

  return (
    <>
      <ResponsiveAppBar />
      <Snackbar
        open={alertOpen}
        // autoHideDuration={6000}
        sx={{ height: "100%" }}
        onClose={handleAlertClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alert.type}
          sx={{ fontSize: "18px", pt: 1.5, pb: 1.5, whiteSpace: "pre-line" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      {isLoading ? (
        <BackdropLoader show={isLoading} />
      ) : (
        <>
          <BoxPaper>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <DashPaper sx={{ pb: 1.5, pt: 2 }}>
                  <Box textAlign="center" sx={{ pb: 2 }}>
                    <Title sx={{ color: "#1976d2", fontWeight: "bold" }}>
                      Collect TOV Information
                    </Title>
                  </Box>
                  <Grid
                    container
                    spacing={{ xs: 1.75, sm: 2, md: 2, lg: 2 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={2}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography variant="subtitle2">HCP Name</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      md={4}
                      pb={{ xs: 2 }}
                      display="flex"
                      justifyContent="center"
                    >
                      <Input
                        disabled
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                            backgroundColor: "#EDECEA",
                          },
                        }}
                        size="small"
                        fullWidth
                        value={`${userSession.userFirstName} ${userSession.userLastName}`}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={2}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography variant="subtitle2">Email</Typography>
                    </Grid>
                    <Grid
                      item
                      pb={{ xs: 2 }}
                      xs={12}
                      sm={8}
                      md={4}
                      display="flex"
                      justifyContent="center"
                    >
                      <Input
                        disabled
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                            backgroundColor: "#EDECEA",
                          },
                        }}
                        size="small"
                        fullWidth
                        value={userSession.userEmail}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={2}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography variant="subtitle2">Mobile</Typography>
                    </Grid>
                    <Grid
                      item
                      pb={{ xs: 2, sm: 0 }}
                      xs={12}
                      sm={8}
                      md={4}
                      display="flex"
                      justifyContent="center"
                    >
                      <Input
                        disabled
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                            backgroundColor: "#EDECEA",
                          },
                        }}
                        size="small"
                        value={
                          userSession && userSession.userPhoneNumber === "-1"
                            ? "NA"
                            : userSession && userSession.userPhoneNumber
                        }
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={2}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography variant="subtitle2">
                        National ID<Asterisk>*</Asterisk>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      pb={{ xs: 2, sm: 0 }}
                      xs={12}
                      sm={8}
                      md={4}
                      display="flex"
                      justifyContent="center"
                    >
                      <Input
                        size="small"
                        error={nationalIDHT !== ""}
                        helperText={nationalIDHT}
                        fullWidth
                        disabled={
                          userDetails && userDetails.national_id.length !== 0
                        }
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                            backgroundColor: "#EDECEA",
                          },
                        }}
                        value={nationalId}
                        onChange={(e) => handleChange(e)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={2}
                      display="flex"
                      justifyContent="center"
                      style={{ paddingTop: "30px" }}
                    >
                      <Typography variant="subtitle2">
                        Professional ID<Asterisk>*</Asterisk>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      pb={{ xs: 2, sm: 0 }}
                      xs={12}
                      sm={8}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      style={{ paddingTop: "30px" }}
                    >
                      <Input
                        size="small"
                        error={professionaIDHT !== ""}
                        helperText={professionaIDHT}
                        fullWidth
                        disabled={
                          userDetails &&
                          userDetails.professional_id.length !== 0
                        }
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                            backgroundColor: "#EDECEA",
                          },
                        }}
                        value={professionalId}
                        onChange={(e) => handleprofessionalidChange(e)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      display="flex"
                      justifyContent="left"
                    >
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      md={1}
                      display="flex"
                      justifyContent="flex-start"
                      style={{ paddingTop: "30px" }}
                    >
                      <Typography variant="subtitle2">
                        Signature<Asterisk>*</Asterisk>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      md={8}
                      display="flex"
                      sx={{
                        justifyContent: {
                          xs: "center",
                          sm: "center",
                          md: "left",
                        },
                        ...signatureError,
                      }}
                      style={{ paddingTop: "30px" }}
                    >
                      {/*Signature Pad opened for all the time*/}
                      <SignaturePad
                        ref={sigCanvas}
                        canvasProps={{
                          style: {
                            width: "90%",
                            border: "1px solid black",
                            height: 300,
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <Stack
                        width={"100%"}
                        textAlign="center"
                        justifyContent="center"
                        direction={{ xs: "column", sm: "row", md: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                      >
                        <Button variant="contained" onClick={Save}>
                          Save
                        </Button>
                        <Button variant="contained" onClick={Clear}>
                          Clear
                        </Button>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {signatureError && (
                        <Typography
                          variant="caption"
                          color="#d32f2f"
                          pl={0.5}
                        >
                          <Asterisk>*</Asterisk>Please Provide the Signature
                          <Asterisk>*</Asterisk>
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} textAlign="center">
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={disableSubmit}
                        sx={{ mt: 1.5, fontWeight: "bold" }}
                        onClick={() => handleSubmit()}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </DashPaper>
              </Grid>
            </Grid>
          </BoxPaper>
          <Footer />
        </>
      )}
    </>
  );
}
export default HCPForm;
