import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { SessionContext } from "../providers/provider";
import { getRefreshToken } from "../services/authservice";
import { msalConfig } from "../constants/authConfig";
import { CountdownTimer } from "./common";
import BackdropLoader from "./loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
};

function SessionModal() {
  const [idleModal, setIdleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userSession = useContext(SessionContext);
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setIdleModal(false);
  };
  ;
  const sessionTimeout = (idleTimeout) => {
    let id = setTimeout(function () {
      setIdleModal(true);
      clearTimeout(id);
      id = null;
    }, idleTimeout);
  };

  const handleTokenRefresh = () => {
    async function submitData() {
      setIsLoading(true);
      let access_token = localStorage.getItem("apitoken");
      let newtoken = await getRefreshToken(access_token, userSession.userEmail);
      localStorage.setItem("apitoken", newtoken);
      setIsLoading(false);
    }
    submitData();
    handleClose();
    const timeoutDuration = userSession.userRole === "HCP" ? 25 : 55;
    let idleTimeout = 1000 * 60 * timeoutDuration;
    sessionTimeout(idleTimeout);
  };

  const logOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      document.cookie = cookies[i] + "=; expires=" + new Date(0).toUTCString();
    }
    window.location.replace(msalConfig.auth.redirectUri + "/");
    handleClose();
    clearTimeout(sessionTimeout);
  };

  useEffect(() => {
    if (userSession.userRole && userSession.userRole === "HCP") {
      let timeoutDuration = 25;
      let idleTimeout = 1000 * 60 * timeoutDuration;
      sessionTimeout(idleTimeout);
    } else if (
      (userSession.userRole && userSession.userRole) === "Admin" ||
      (userSession.userRole && userSession.userRole) === "Meeting Owner" ||
      (userSession.userRole && userSession.userRole) === "Sales Rep"
    ) {
      let timeoutDuration = 55;
      let idleTimeout = 1000 * 60 * timeoutDuration;
      sessionTimeout(idleTimeout);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSession]);

  return (
    <>
      {isLoading ? (
        <BackdropLoader show={isLoading} />
      ) : (
        <Modal
          open={idleModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography textAlign={"center"}>
              <p>
                Your session will expire in{" "}
                <CountdownTimer initialSeconds={300} />
              </p>
              <p>Do you want to continue the session ? </p>
            </Typography>
            <Stack
              spacing={5}
              textAlign="right"
              direction={"row"}
              justifyContent="center"
              mt={2}
            >
              <Button variant="contained" onClick={() => handleTokenRefresh()}>
                Refresh
              </Button>
              <Button variant="contained" onClick={() => logOut()}>
                Logout
              </Button>
            </Stack>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default SessionModal;
