/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import jwt_decode from "jwt-decode";
import MainPage from "./pages/mainPage";
import { MsalContext, SessionContext } from "./providers/provider";
import Login from "./pages/login";
import {
  callMsGraph,
  callMsGraphGroup,
  callMsGraphGroupMember,
  callMsGraphSalesGroup,
} from "./constants/graph";
import {
  getMsalToCustomToken,
  getPassportToCustomToken,
} from "./services/authservice";
import {
  getUserDetailsByEmail,
  upsertUser,
  getSecrets,
} from "./services/userservice";
import { getMeetingIdVerification } from "./services/hcpmappingservice";
import "./App.css";

function App() {
  const msalInstance = useContext(MsalContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isToken, setToken] = useState(false);
  // incase of HCP Users UserId is the passport ID
  const [userId, setUserId] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userRoleId, setUserRoleId] = useState("");
  const [userAffiliate, setUserAffiliate] = useState("");
  const [userAffiliateId, setUserAffiliateId] = useState("");
  const [userAffiliateCode, setUserAffiliateCode] = useState("");
  const [hcpCustId, setHcpCustId] = useState(null); // CODS ID for HCP's
  const [hcpSpecilaity, setHcpSpecilaity] = useState(null); // CODS ID for HCP's
  const [reactAppAdminGroup, setReactAppAdminGroup] = useState(null);
  const [reactAppEncryptionKey, setReactAppEncryptionKey] = useState(null);
  const [reactAppSecondayGroups, setReactAppSecondayGroups] = useState(null);

  useEffect(() => {
    async function handleUserSession() {
      // if user has logged in via msal
      if (window.location.hash.length > 0) {
        setToken(true);
        msalInstance
          .handleRedirectPromise()
          .then((authResult) => {
            let accessToken = authResult.accessToken;
            let idToken = authResult.idToken;
            localStorage.setItem("msalToken", accessToken);
            callMsGraph(accessToken)
              .then((graphresponse) => {
                let accesslevel = "";
                accesslevel = "saudi_tov_meetingowners_dev";
                callMsGraphGroup(graphresponse.id, accessToken)
                  .then((groupres) => {
                    (callMsGraphGroupMember(graphresponse.id, accessToken))
                      .then((grpupmember) => {
                        (callMsGraphSalesGroup(graphresponse.id, accessToken))               //Salesrep group called
                          .then((grpupmember) => {
                            if (
                              grpupmember.value.filter(
                                (x) => x.displayName === "saudi_tov_salesrep_dev"
                              ).length === 1
                            ) {
                              accesslevel = "saudi_tov_salesrep_dev";
                              getMsalToCustomToken(graphresponse.mail, idToken)
                                .then((apitoken) => {
                                  localStorage.setItem("apitoken", apitoken);
                                  getSecrets(apitoken)
                                    .then((secrets) => {
                                      setReactAppAdminGroup(
                                        secrets.result.REACT_APP_ADMIN_GROUP
                                      );
                                      setReactAppEncryptionKey(
                                        secrets.result.REACT_APP_ENCRYPTION_KEY
                                      );
                                      setReactAppSecondayGroups(
                                        secrets.result.REACT_APP_GROUP_ID
                                      );
                                      getUserDetailsByEmail(
                                        graphresponse.mail,
                                        apitoken
                                      )
                                        .then((userData) => {
                                          // make API call to db to get user details
                                          if (userData.statusCode === "200") {
                                            if (userData.result) {
                                              const userDetails = userData.result;
                                              setUserId(userDetails.lilly_id);
                                              setUserFirstName(
                                                userDetails.user_firstname
                                              );
                                              setUserLastName(
                                                userDetails.user_lastname
                                              );
                                              setUserEmail(userDetails.user_email);
                                              setUserPhoneNumber(
                                                graphresponse.mobilePhone
                                              );
                                              setUserRole(userDetails.role_name);
                                              setUserRoleId(userDetails.role_id);
                                              setUserAffiliate(
                                                userDetails.affiliate_name
                                              );
                                              setUserAffiliateId(
                                                userDetails.affiliate_id
                                              );
                                              setUserAffiliateCode(
                                                userDetails.affiliate_code
                                              );
                                              setHcpCustId(null);
                                              setHcpSpecilaity(null);
                                              if (
                                                localStorage.getItem("redirectto")
                                              ) {
                                                window.location.assign(
                                                  window.location.origin +
                                                  localStorage.getItem("redirectto")
                                                );
                                              } else {
                                                if (
                                                  accesslevel ===
                                                  "saudi_tov_salesrep_dev"
                                                ) {
                                                  window.location.assign(
                                                    window.location.origin +
                                                    "/salesrep/dashboard"
                                                  );
                                                } else if (
                                                  accesslevel ===
                                                  "saudi_tov_admins_dev"
                                                ) {
                                                  window.location.assign(
                                                    window.location.origin +
                                                    "/admin/dashboard"
                                                  );
                                                }
                                                else {
                                                  window.location.assign(
                                                    window.location.origin +
                                                    "/mo/dashboard"
                                                  );
                                                }
                                              }
                                            }
                                          } else {
                                            throw new Error(
                                              "no such user in the system"
                                            );
                                          }
                                        })
                                        .catch(() => {
                                          let data = {
                                            affiliate_code:
                                              graphresponse.usageLocation,
                                            role_name: accesslevel,
                                            lilly_id:
                                              graphresponse.onPremisesSamAccountName,
                                            user_firstname: graphresponse.givenName,
                                            user_lastname: graphresponse.surname,
                                            user_email: graphresponse.mail,
                                          };
                                          upsertUser(data, apitoken)
                                            .then((res) => {
                                              const userDetails = res.result;
                                              setUserId(userDetails.lilly_id);
                                              setUserFirstName(
                                                userDetails.user_firstname
                                              );
                                              setUserLastName(
                                                userDetails.user_lastname
                                              );
                                              setUserEmail(userDetails.user_email);
                                              setUserPhoneNumber(
                                                graphresponse.mobilePhone
                                              );
                                              setUserRole(userDetails.role_name);
                                              setUserRoleId(userDetails.role_id);
                                              setUserAffiliate(
                                                userDetails.affiliate_name
                                              );
                                              setUserAffiliateId(
                                                userDetails.affiliate_id
                                              );
                                              setUserAffiliateCode(
                                                userDetails.affiliate_code
                                              );
                                              setHcpCustId(null);
                                              setHcpSpecilaity(null);
                                              setIsLoggedIn(true);
                                              if (
                                                localStorage.getItem("redirectto")
                                              ) {
                                              } else {
                                                if (
                                                  accesslevel ===
                                                  "saudi_tov_salesrep_dev"
                                                ) {
                                                  window.location.assign(
                                                    window.location.origin +
                                                    "/salesrep/dashboard"
                                                  );
                                                } else if (
                                                  accesslevel ===
                                                  "saudi_tov_admins_dev"
                                                ) {
                                                  window.location.assign(
                                                    window.location.origin +
                                                    "/admin/dashboard"
                                                  );
                                                }
                                                else {
                                                  window.location.assign(
                                                    window.location.origin +
                                                    "/mo/dashboard"
                                                  );
                                                }
                                              }
                                            })
                                            .catch((err) => {
                                              console.log(
                                                "Error in getting data from API upsertUser",
                                                err
                                              );
                                            });
                                        });
                                    })
                                    .catch((err) => {
                                      localStorage.clear();
                                      window.location.reload();
                                    });
                                })
                                .catch((err) => {
                                  console.log("we have no new api token", err);
                                });
                            }
                            else {
                              if (graphresponse.usageLocation === "SA") {
                                getMsalToCustomToken(graphresponse.mail, idToken)
                                  .then((apitoken) => {
                                    localStorage.setItem("apitoken", apitoken);
                                    getSecrets(apitoken)
                                      .then((secrets) => {
                                        setReactAppAdminGroup(
                                          secrets.result.REACT_APP_ADMIN_GROUP
                                        );
                                        setReactAppEncryptionKey(
                                          secrets.result.REACT_APP_ENCRYPTION_KEY
                                        );
                                        setReactAppSecondayGroups(
                                          secrets.result.REACT_APP_GROUP_ID
                                        );
                                        getUserDetailsByEmail(
                                          graphresponse.mail,
                                          apitoken
                                        )
                                          .then((userData) => {
                                            // make API call to db to get user details
                                            if (userData.statusCode === "200") {
                                              if (userData.result) {
                                                const userDetails = userData.result;
                                                setUserId(userDetails.lilly_id);
                                                setUserFirstName(
                                                  userDetails.user_firstname
                                                );
                                                setUserLastName(
                                                  userDetails.user_lastname
                                                );
                                                setUserEmail(userDetails.user_email);
                                                setUserPhoneNumber(
                                                  graphresponse.mobilePhone
                                                );
                                                setUserRole(userDetails.role_name);
                                                setUserRoleId(userDetails.role_id);
                                                setUserAffiliate(
                                                  userDetails.affiliate_name
                                                );
                                                setUserAffiliateId(
                                                  userDetails.affiliate_id
                                                );
                                                setUserAffiliateCode(
                                                  userDetails.affiliate_code
                                                );
                                                setHcpCustId(null);
                                                setHcpSpecilaity(null);
                                                if (
                                                  localStorage.getItem("redirectto")
                                                ) {
                                                  window.location.assign(
                                                    window.location.origin +
                                                    localStorage.getItem(
                                                      "redirectto"
                                                    )
                                                  );
                                                } else {
                                                  if (
                                                    accesslevel ===
                                                    "saudi_tov_admins_dev"
                                                  ) {
                                                    window.location.assign(
                                                      window.location.origin +
                                                      "/admin/dashboard"
                                                    );
                                                  } else {
                                                    window.location.assign(
                                                      window.location.origin +
                                                      "/mo/dashboard"
                                                    );
                                                  }
                                                }
                                              }
                                            } else {
                                              throw new Error(
                                                "no such user in the system"
                                              );
                                            }
                                          })
                                          .catch(() => {
                                            let data = {
                                              affiliate_code:
                                                graphresponse.usageLocation,
                                              role_name: accesslevel,
                                              lilly_id:
                                                graphresponse.onPremisesSamAccountName,
                                              user_firstname: graphresponse.givenName,
                                              user_lastname: graphresponse.surname,
                                              user_email: graphresponse.mail,
                                            };
                                            upsertUser(data, apitoken)
                                              .then((res) => {
                                                const userDetails = res.result;
                                                setUserId(userDetails.lilly_id);
                                                setUserFirstName(
                                                  userDetails.user_firstname
                                                );
                                                setUserLastName(
                                                  userDetails.user_lastname
                                                );
                                                setUserEmail(userDetails.user_email);
                                                setUserPhoneNumber(
                                                  graphresponse.mobilePhone
                                                );
                                                setUserRole(userDetails.role_name);
                                                setUserRoleId(userDetails.role_id);
                                                setUserAffiliate(
                                                  userDetails.affiliate_name
                                                );
                                                setUserAffiliateId(
                                                  userDetails.affiliate_id
                                                );
                                                setUserAffiliateCode(
                                                  userDetails.affiliate_code
                                                );
                                                setHcpCustId(null);
                                                setHcpSpecilaity(null);
                                                setIsLoggedIn(true);
                                                if (
                                                  localStorage.getItem("redirectto")
                                                ) {
                                                } else {
                                                  if (
                                                    accesslevel ===
                                                    "saudi_tov_admins_dev"
                                                  ) {
                                                    // window.location.assign(window.location.origin + '/admin/dashboard');
                                                  } else {
                                                    // window.location.replace(window.location.origin + '/mo/dashboard');
                                                  }
                                                }
                                              })
                                              .catch((err) => {
                                                console.log(
                                                  "Error in getting data from API upsertUser",
                                                  err
                                                );
                                              });
                                          });
                                      })
                                      .catch((err) => {
                                        localStorage.clear();
                                        window.location.reload();
                                      });
                                  })
                                  .catch((err) => {
                                    console.log("we have no new api token", err);
                                  });
                                // } else {
                                //   window.location.assign(window.location.origin + '/unauthorized-user');
                              } else {
                                window.location.assign("unauthorized");
                              }
                            }
                          })
                      })
                      .catch((err) => {
                        console.log("Error", err);
                      });
                  })
                  .catch((graphgrouperror) => {
                    console.log("Error", graphgrouperror);
                  });
              })
              .catch((graphresError) => {
                console.log("Error", graphresError);
              });
            //  else {
            //   throw new Error('Access denied');
            // }
          })
          .catch((grapherror) => {
            console.log("Error", grapherror);
          })
          .catch((err) => {
            console.log("Error", err);
          });
      }
      // if user has logged in via passport token
      else if (window.location.search.includes("access_token")) {
        setToken(true);
        let accessToken = window.location.search.replace(/.{14}/, "");
        localStorage.setItem("passportToken", accessToken);
        let decoded = jwt_decode(accessToken);
        getPassportToCustomToken(decoded.email, accessToken)
          .then(async (res) => {
            setUserId(decoded.id);
            setUserFirstName(decoded.firstName);
            setUserLastName(decoded.lastName);
            setUserEmail(decoded.email);
            setUserPhoneNumber(decoded.phone);
            setUserRole("HCP");
            setUserRoleId("3");
            setUserAffiliate("Saudi Arabia");
            setUserAffiliateId("1");
            setUserAffiliateCode(decoded.countryCode);
            setHcpCustId(decoded.customerId);
            setHcpSpecilaity(decoded.specialty);
            localStorage.setItem("apitoken", res);
            await getSecrets(res)
              .then((secrets) => {
                setReactAppAdminGroup(secrets.result.REACT_APP_ADMIN_GROUP);
                setReactAppEncryptionKey(secrets.result.REACT_APP_ENCRYPTION_KEY);
                setReactAppSecondayGroups(secrets.result.REACT_APP_GROUP_ID);
              })
              .catch((err) => {
                localStorage.clear();
                window.location.reload();
              });
            if (localStorage.getItem("redirectto")) {
              let meetingCheck = await getMeetingIdVerification(res, {
                meeting_id: localStorage.getItem("redirectto").split("/")[3],
              });
              if (meetingCheck.message !== "Successful") {
                // window.location.replace(window.location.origin + '/404NotFound');
              } else {
                window.location.assign(localStorage.getItem("redirectto"));
              }
            }
          })
          .catch((err) => {
            console.log("we have no new api token", err);
          });

        setIsLoggedIn(true);
      }
      // if user has custom jwt token
      else if (localStorage.getItem("apitoken")) {
        setToken(true);
        let apiToken = localStorage.getItem("apitoken");
        getSecrets(apiToken)
          .then((secrets) => {
            setReactAppAdminGroup(secrets.result.REACT_APP_ADMIN_GROUP);
            setReactAppEncryptionKey(secrets.result.REACT_APP_ENCRYPTION_KEY);
            setReactAppSecondayGroups(secrets.result.REACT_APP_GROUP_ID);
          })
          .catch((err) => {
            localStorage.clear();
            window.location.reload();
          });
        if (localStorage.getItem("passportToken")) {
          let accessToken = localStorage.getItem("passportToken");
          let decoded = jwt_decode(accessToken);
          setUserId(decoded.id);
          setUserFirstName(decoded.firstName);
          setUserLastName(decoded.lastName);
          setUserEmail(decoded.email);
          setUserPhoneNumber(decoded.phone);
          setUserRole("HCP");
          setUserRoleId("3");
          setUserAffiliate("Saudi Arabia");
          setUserAffiliateId("1");
          setUserAffiliateCode(decoded.countryCode);
          setHcpCustId(decoded.customerId);
          setHcpSpecilaity(decoded.specialty);
          if (localStorage.getItem("redirectto")) {
            let meetingCheck = await getMeetingIdVerification(
              localStorage.getItem("apitoken"),
              {
                meeting_id: localStorage.getItem("redirectto").split("/")[3],
              }
            );
            if (meetingCheck.message !== "Successful") {
              // window.location.replace(window.location.origin + '/404NotFound');
              // window.location.replace(window.location.origin + localStorage.getItem('redirectto'));
            } else {
              // window.location.replace(window.location.origin + '/404NotFound');
            }
          }
        } else {
          let decodedApiToken = jwt_decode(localStorage.getItem("apitoken"));
          // make API call to db to get user details
          getUserDetailsByEmail(decodedApiToken["mailQAIDS"], apiToken)
            .then((userData) => {
              if (userData.result) {
                const userDetails = userData.result;
                setUserId(userDetails.lilly_id);
                setUserFirstName(userDetails.user_firstname);
                setUserLastName(userDetails.user_lastname);
                setUserEmail(userDetails.user_email);
                setUserPhoneNumber(null);
                setUserRole(userDetails.role_name);
                setUserRoleId(userDetails.role_id);
                setUserAffiliate(userDetails.affiliate_name);
                setUserAffiliateId(userDetails.affiliate_id);
                setUserAffiliateCode(userDetails.affiliate_code);
                setHcpCustId(null);
                setHcpSpecilaity(null);
                if (localStorage.getItem("redirectto")) {
                }
              }
            })
            .catch((err) => {
              console.log(
                "Error in getting data from API getUserDetailsByEmail",
                err
              );
            });
        }
        setIsLoggedIn(true);
      } else if (
        localStorage.getItem("redirectto") &&
        !localStorage.getItem("apitoken") &&
        !localStorage.getItem("msalToken") &&
        !localStorage.getItem("passportToken")
      ) {
        if (!localStorage.getItem("redirectto").includes("submit")) {
          localStorage.clear();
          sessionStorage.clear();
          const cookies = document.cookie.split(";");
          for (let i = 0; i < cookies.length; i++) {
            document.cookie =
              cookies[i] + "=; expires=" + new Date(0).toUTCString();
          }
          window.location.replace(window.location.origin);
        }
        // goto application basics
      }
    }
    handleUserSession();
    // if user has not logged in
  }, []);

  return (
    <div>
      {isLoggedIn ? (
        <SessionContext.Provider
          value={{
            userId,
            userFirstName,
            userLastName,
            userPhoneNumber,
            userEmail,
            userRole,
            userAffiliateCode,
            userRoleId,
            userAffiliate,
            userAffiliateId,
            hcpCustId,
            hcpSpecilaity,
            reactAppAdminGroup,
            reactAppEncryptionKey,
            reactAppSecondayGroups,
          }}
        >
          <MainPage />
        </SessionContext.Provider>
      ) : (
        <Login token={isToken} />
      )}
    </div>
  );
}

export default App;
