import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid, Button, Typography } from "@mui/material";
import { useState, useContext } from "react";
import dayjs from "dayjs";
import { Input, Title } from "../components/styledComponents";
import {
  getFilteredAttendee,
  getFilteredAttendeeDetailsById,
} from "../services/attendeeservice";
import { getFilteredHcp } from "../services/hcpmappingservice";
import { SessionContext } from "../providers/provider";
import { useNavigate } from "react-router-dom";
import BackdropLoader from "./loader";
import "dayjs/locale/en-gb";

function CommonSearch(props) {
  let navigate = useNavigate();
  const userSession = useContext(SessionContext);
  const [meetingId, setMeetingId] = useState();
  const [nationalId, setNationalId] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [isLoading, setIsLoading] = useState(false);

  function searchByAdmin() {
    setIsLoading(true);
    async function searchedData() {
      let accessToken = localStorage.getItem("apitoken");
      let data = {
        start_date: startDate,
        end_date: endDate,
        meeting_code: meetingId,
        national_id: nationalId,
        professional_id: null,
        lilly_id: userSession.userId,
      };
      let resultData = await getFilteredAttendee(accessToken, data);
      props.callbackFunc(resultData[0], data);
      setIsLoading(false);
    }
    searchedData();
  }

  function searchByMO() {
    setIsLoading(true);
    async function searchedData() {
      let accessToken = localStorage.getItem("apitoken");
      let data = {
        start_date: startDate,
        end_date: endDate,
        meeting_code: meetingId,
        national_id: nationalId,
        professional_id: null,
        lilly_id: userSession.userId,
      };
      let resultData = await getFilteredAttendeeDetailsById(accessToken, data);
      props.callbackFunc(resultData[0], data);
      setIsLoading(false);
    }
    searchedData();
  }

  function clear() {
    setMeetingId("");
    setNationalId("");
    setStartDate(null);
    setEndDate(dayjs(new Date()));
    props.callbackFunc(props.allData);
  }

  return (
    <>
      {isLoading ? (
        <BackdropLoader show={isLoading} />
      ) : (
        <Grid container spacing={1.5} direction={"row"}>
          <Grid item xs={12} sm={12} md={12} m={1}>
            <Title variant="h5">Search</Title>
          </Grid>

          <Grid
            item
            width={1}
            sm={4}
            md={2}
            mt={0.5}
            display={"flex"}
            justifyContent={"center"}
          >
            <Typography variant="subtitle2">National ID</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={4}
            display={"flex"}
            justifyContent={"center"}
          >
            <Input
              size="small"
              fullWidth
              value={nationalId}
              onChange={(e) => {
                setNationalId(e.target.value);
              }}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            width={1}
            xs={12}
            sm={4}
            md={2}
            display={"flex"}
            justifyContent={"center"}
          >
            <Typography variant="subtitle2">Meeting ID</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={4}
            display={"flex"}
            justifyContent={"center"}
          >
            <Input
              size="small"
              value={meetingId}
              onChange={(e) => {
                setMeetingId(e.target.value);
              }}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            width={1}
            xs={12}
            sm={4}
            md={2}
            mt={1}
            display={"flex"}
            justifyContent={"center"}
          >
            <Typography variant="subtitle2">Meeting Start Date</Typography>
          </Grid>

          <Grid item xs={12} sm={8} md={4} justifyContent={"center"}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DemoContainer
                components={["DatePicker", "DatePicker"]}
                sx={{ ml: "16px" }}
              >
                <DatePicker
                  slotProps={{ textField: { size: "small" }, field: { clearable: true } }}
                  value={startDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      setStartDate(newValue);
                    } else {
                      setStartDate(null);
                    }
                  }}
                  sx={{ width: "95%" }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>

          <Grid
            item
            width={1}
            xs={12}
            sm={4}
            md={2}
            mt={1}
            display={"flex"}
            justifyContent={"center"}
          >
            <Typography variant="subtitle2">Meeting End Date</Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={4} justifyContent={"center"}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DemoContainer
                components={["DatePicker", "DatePicker"]}
                sx={{ ml: "16px" }}
              >
                <DatePicker
                  slotProps={{ textField: { size: "small" }, field: { clearable: true } }}
                  value={endDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      setEndDate(newValue);
                    } else {
                      setEndDate(null);
                    }
                  }}
                  sx={{ width: "95%" }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid
            container
            sx={{ textAlign: "center", justifyContent: "center", mt: 1 }}
            spacing={2}
          >
            <Grid item>
              {userSession.userRole === "Admin" ? (
                <Button
                  sx={{ fontWeight: "bold" }}
                  variant="contained"
                  onClick={() => {
                    searchByAdmin();
                  }}
                >
                  Search
                </Button>
              ) : (
                <Button
                  sx={{ fontWeight: "bold" }}
                  variant="contained"
                  onClick={() => {
                    searchByMO();
                  }}
                >
                  Search
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                sx={{ fontWeight: "bold" }}
                variant="contained"
                onClick={() => {
                  clear();
                }}
              >
                Clear
              </Button>
            </Grid>
            <Grid item>
              {(userSession.userRole === "Admin" || userSession.userRole === "Sales Rep") ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(`/viewhcpdata`);
                  }}
                  sx={{ fontWeight: "bold" }}
                  color="success"
                >
                  View HCP Data
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    navigate(`/mo/generateqr`);
                  }}
                  sx={{ fontWeight: "bold" }}
                >
                  Generate QR Code
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export { CommonSearch };

function HcpFilter(props) {
  const [nationalId, setNationalId] = useState();
  const [hcpName, setHcpName] = useState();
  const [professionalId, setProfessionalId] = useState();
  const [customerId, setCustomerId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  function hcpsearch() {
    async function searchedData() {
      setIsLoading(true);
      let accessToken = localStorage.getItem("apitoken");
      let data = {
        hcp_name: hcpName,
        national_id: nationalId,
        professional_id: professionalId,
        customer_id: customerId,
      };
      let filteredData = await getFilteredHcp(accessToken, data);
      props.callbackFunc(filteredData.result[0], data);
      setIsLoading(false);
    }
    searchedData();
  }

  function clear() {
    setHcpName("");
    setNationalId("");
    setProfessionalId("");
    setCustomerId("");
    props.callbackFunc(props.allData);
  }

  return (
    <>
      {isLoading ? (
        <BackdropLoader show={isLoading} />
      ) : (
        <Grid container spacing={1.5} direction={"row"}>
          <Grid
            container
            spacing={2}
            sx={{ p: 3, pt: 1 }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={12} m={1}>
              <Title variant="h5">Search</Title>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={2}
              display="flex"
              justifyContent="center"
            >
              <Typography variant="subtitle2">HCP Name</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={4}
              display="flex"
              justifyContent="center"
            >
              <Input
                size="small"
                fullWidth
                id="outlined-basic"
                value={hcpName}
                variant="outlined"
                onChange={(e) => {
                  setHcpName(e.target.value);
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={2}
              display="flex"
              justifyContent="center"
            >
              <Typography variant="subtitle2">National ID</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={4}
              display="flex"
              justifyContent="center"
            >
              <Input
                size="small"
                fullWidth
                id="outlined-basic"
                value={nationalId}
                variant="outlined"
                onChange={(e) => {
                  setNationalId(e.target.value);
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={2}
              display="flex"
              justifyContent="center"
            >
              <Typography variant="subtitle2">Professional ID</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={4}
              display="flex"
              justifyContent="center"
            >
              <Input
                size="small"
                fullWidth
                id="outlined-basic"
                value={professionalId}
                variant="outlined"
                onChange={(e) => {
                  setProfessionalId(e.target.value);
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={2}
              display="flex"
              justifyContent="center"
            >
              <Typography variant="subtitle2">Customer ID</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={4}
              display="flex"
              justifyContent="center"
            >
              <Input
                size="small"
                fullWidth
                id="outlined-basic"
                value={customerId}
                variant="outlined"
                onChange={(e) => {
                  setCustomerId(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={5}
            direction={"row"}
            sx={{
              textAlign: "center",
              justifyContent: "center",
              pb: 2,
            }}
          >
            <Grid item>
              <Button
                sx={{ fontWeight: "bold" }}
                variant="contained"
                onClick={() => {
                  hcpsearch();
                }}
              >
                Search
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{ fontWeight: "bold" }}
                variant="contained"
                onClick={() => {
                  clear();
                }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export { HcpFilter };
